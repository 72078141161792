import React from 'react';
import { graphql, Link } from 'gatsby';
import { Container, ListGroup } from 'react-bootstrap';
import Seo from '../components/seo';
import Layout from '../components/layout';
import TopImage from '../images/top.jpg';
// import { StaticImage } from 'gatsby-plugin-image';

const IndexPage = ({ data }) => {

  const getSlug = (path) => {
    const splitPath = path.match(/(\/posts\/)([^\/]+\/)([^\/]+\/)/);
    return splitPath ? `${splitPath[1]}${splitPath[3]}` : path;
  }

  return (
    <Layout data={data}>
      <Seo title="footlog開発者のブログ" />
      <div className="mb-5 py-4" style={{backgroundImage: `url(${TopImage})`, width: "100%", backgroundSize: "cover", backgroundPosition: "center", objectFit: "cover", color:"black"}}>
        <Container className="border rounded py-3 px-3" style={{backgroundColor: 'rgba(248, 249, 250, 0.7)', textShadow: '1px 1px 2px white'}}>
          <h1>footlog開発者のブログ</h1>
          <p className="mb-0 mt-3">このブログは、footlogの開発者である非エンジニアの２人が運営しています。</p>
          <p className="mb-0">開発にあたって勉強したことや検討したことを備忘録として投稿していければと思います。</p>
          <p className="mb-0">また、本業でも簡易な開発をおこなっているので、記事にして投稿する予定です。</p>
        </Container>
      </div>
      <Container>
        <h5>投稿記事数：{ data.allMarkdownRemark.totalCount }</h5>
        <ListGroup variant="flush">
          {
            data.allMarkdownRemark.edges.map(({ node }) => (
              <ListGroup.Item key={node.id} className="bg-light">
                <Link to={getSlug(node.fields.slug)} className="text-body" style={{"textDecoration": "none"}}>
                  <div className="d-inline text-secondary">{ node.frontmatter.created_at }</div>
                  <div className="d-inline ms-3 text-secondary">タグ： { node.frontmatter.tags.join(', ') }</div>
                  <div className="d-inline ms-3 text-secondary">by { node.frontmatter.author }</div>
                  <h3>{ node.frontmatter.title }</h3>
                  <p>{node.excerpt}</p>
                </Link>
              </ListGroup.Item>
            ))
          }
        </ListGroup>
      </Container>
    </Layout>
  )
}

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(sort: {fields: frontmatter___created_at, order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            created_at(formatString: "YYYY-MM-DD")
            tags
            author
          }
          excerpt
          fields {
            slug
          }
        }
      }
    }
  }
`